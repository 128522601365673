<template>
  <index-content>
    <template #options>
      <nb-refresh @on-click="load" />
    </template>

    <search-condition :label-width="100">
      <n-field-selection
        v-model="domain"
        selection-name="system.digest.domain"
        label-align="left"
        label="domain"
        @on-change="load"
      />
    </search-condition>

    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th class="domain">{{ $t('label.domain') }}</th>
            <th class="name">{{ $t('label.name') }}</th>
            <th class="status">{{ $t('label.status') }}</th>
            <th v-table-action>{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.domain | hyphen }}</td>
            <td>{{ row.name }}</td>
            <td><n-icon-boolean :value="row.consistent" /></td>
            <td class="n-button-box">
              <n-button text="detail" custom-icon="fas fa-info-circle" @on-click="onBtnDetail(row)" />
              <nb-confirm text="reset" @on-ok="onBtnReset(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import SystemView from './view';
import ModalDigest from './ModalDigest';
import { fetch, reset } from '@/api/system/system-digest';

export default SystemView.extend({
  name: 'SystemDigest',

  data() {
    return {
      preload: true,
      domain: 'COMMON',
    };
  },

  methods: {
    doLoad() {
      return fetch(this.domain);
    },

    parse(rows) {
      this.records = rows;
    },

    onBtnReset(row) {
      return reset(this.domain, row.name);
    },

    onBtnDetail(row) {
      this.createModal(ModalDigest, { model: row });
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/style/table';

.n-table {
  min-width: 726px;

  .domain {
    width: 140px;
  }

  .status {
    width: 8rem;
  }

  th.rw-action {
    width: 9rem;
  }

  th.ro-action {
    width: $action-detail;
  }
}
</style>
