import { $fetch, $post } from '@/services/axios';

export function fetch(domain) {
  return $fetch(`system/digest/${domain}`);
}

export function reset(domain, name) {
  return $post(`system/digest/${domain}`, { name });
}

export function loadDetail(domain, name) {
  return $fetch(`system/digest/${domain}/${name}`);
}
