<template>
  <n-modal v-bind="$props" :escapable="true" :visible="display" :width="800" :detail="true">
    <template v-for="(item, index) in digests">
      <h4 :key="item.digest" class="p-title mb-2">Digest: {{ item.digest }}</h4>
      <div :key="item.digest + index" class="table-responsive">
        <table :key="item.digest + index" class="table table-striped table-bordered table-hover text-center n-table">
          <thead>
            <tr>
              <th class="appId">{{ $t('label.appId') }}</th>
              <th class="processId">{{ $t('label.processId') }}</th>
              <th class="appName">{{ $t('label.appName') }}</th>
              <th class="appHost">{{ $t('label.appHost') }}</th>
              <th class="n-table-timestamp">{{ $t('label.lastUpdate') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="digest in item.list" :key="digest.processId">
              <td>{{ digest.appId }}</td>
              <td>{{ digest.processId }}</td>
              <td class="n-ellipsis" :title="digest.appName">{{ digest.appName }}</td>
              <td class="n-ellipsis" :title="digest.appHost">{{ digest.appHost }}</td>
              <td>{{ digest.updateDatetime | datetime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { map, sortBy, groupBy } from 'lodash-es';
import { loadDetail } from '@/api/system/system-digest';

export default NModal.extend({
  name: 'SystemDigestDetail',

  components: { NModal },

  data() {
    return {
      digests: [],
      loading: false,
      display: false,
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.loading = true;
      loadDetail(this.model.domain, this.model.name)
        .then(records => {
          const items = records.map(o => ({ ...o.app, ...o.digest }));
          const grouped = groupBy(items, m => m.digest);
          return map(grouped, (array, key) => ({ digest: key, list: sortBy(array, o => o.appId) }));
        })
        .then(rows => {
          this.digests = rows;
          this.$nextTick(() => {
            this.display = true;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>
<style lang="scss" scoped>
.p-title {
  font-size: 15px;
}
.appId {
  width: 15%;
}
.processId {
  width: 15%;
}
.appHost {
  width: 25%;
}
</style>
